import { gql } from '@apollo/client'

export const LOGIN_SA_USER = gql`
  mutation SignInPartner($password: String!, $email: String!) {
    signInPartner(password: $password, email: $email) {
      login
      refreshToken
      token
    }
  }
`

export const UPDATE_TOKENS = gql`
  mutation updateAccessTokensPartner($refreshToken: String) {
    updateAccessTokensPartner(refreshToken: $refreshToken) {
      login
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation ResetPasswordPartner($email: String!) {
    resetPasswordPartner(email: $email) {
      email
    }
  }
`

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($newPassword: String!, $activationKey: String!) {
    changePassword(newPassword: $newPassword, activationKey: $activationKey) {
      email
    }
  }
`

export const LOGOUT = gql`
  mutation Logout {
    logout {
      login
    }
  }
`

export const CONFIRM_EMAIL = gql`
  mutation ConfirmEmail($confirmationToken: String!) {
    confirmEmail(confirmationToken: $confirmationToken) {
      email
      firstName
      id
      lastName
    }
  }
`

export const CREATE_PASSWORD = gql`
  mutation CreatePassword($newPassword: String!, $activationKey: String!) {
    createPartnerPassword(newPassword: $newPassword, activationKey: $activationKey) {
      email
    }
  }
`
