import { extendTheme } from '@chakra-ui/react'

import ArrowShort from 'app/icons/arrow-short.png'
import ArrowLong from 'app/icons/arrow-long.png'

export const breakpoints = {
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  xxl: '1400px',
}

export const theme = extendTheme({
  styles: {
    global: {
      'html, body': {
        bg: 'background',
        color: 'text.300',
      },
    },
  },
  breakpoints,
  fonts: {
    body: 'Roboto, Helvetica, Arial, sans-serif',
    heading: 'Lora, Helvetica, Arial, sans-serif',
  },
  fontSizes: {
    xs: '0.75rem', // 12px
    sm: '0.875rem', // 14px
    md: '1rem', // 16px
    lg: '1.125rem', // 18px
    xl: '1.25rem', // 20px
    '2xl': '1.5rem', // 24px
    '3xl': '1.875rem', // 30px
    '4xl': '2.25rem', // 36px
    '5xl': '2.875rem', // 46px
    '6xl': '3.75rem', // 60px
    '7xl': '4.5rem', // 72x
    '8xl': '6rem',
    '9xl': '8rem',
  },
  components: {
    Heading: {
      baseStyle: {
        fontWeight: 500,
      },
      sizes: {
        '2xl': {
          lineHeight: 1.2,
        },
      },
      defaultProps: {
        size: '2xl',
      },
    },
    FormLabel: {
      baseStyle: {
        fontSize: 'lg',
      },
    },
    Button: {
      sizes: {
        md: {
          fontSize: 'md',
          height: '52px',
        },
      },
      variants: {
        primary: {
          bg: 'orange.200',
          border: 'none',
          borderRadius: '100px',
          fontWeight: 500,
          letterSpacing: '1.25px',
          _hover: {
            _disabled: {
              bg: 'orange.200',
            },
            _after: {
              backgroundImage: `url(${ArrowLong})`,
              width: '24px',
              color: 'inherit',
            },
          },
          _after: {
            content: '""',
            backgroundImage: `url(${ArrowShort})`,
            backgroundSize: '100% 100%',
            left: 'auto',
            height: '6px',
            width: '12px',
            marginLeft: '8px',
          },
        },
        link: {
          _hover: {
            textDecoration: 'none',
            color: 'purple.300',
          },
        },
      },
      // The default size and variant values
      defaultProps: {
        size: 'md',
        variant: 'primary',
      },
    },
    Select: {
      variants: {
        solid: {
          borderColor: 'navy.100',
        },
      },
      defaultProps: {
        focusBorderColor: 'navy.100',
      },
    },
    Input: {
      baseStyle: ({ isInvalid, errorBorderColor }: { isInvalid: boolean; errorBorderColor: string }) => ({
        field: {
          bg: 'rgba(216, 218, 238, 0.2)',
          border: '2px solid',
          borderColor: isInvalid ? errorBorderColor : 'navy.100',
          _hover: {
            borderColor: isInvalid ? errorBorderColor : 'purple.200',
          },
        },
      }),
      variants: {
        primary: {
          field: {
            fontSize: 'xl',
            height: '62px',
            padding: '0 16px',
          },
        },
      },
      defaultProps: {
        errorBorderColor: 'red.300',
      },
    },
  },
  colors: {
    navy: {
      100: '#D8DAEE',
      200: '#001194',
      300: '#000A54',
    },
    purple: {
      100: '#F7EFFF',
      200: '#B35DFF',
      300: '#8F4ACC',
      400: '#38047C',
    },
    orange: {
      100: '#FFE8CC',
      200: '#FF8A00',
      300: '#CC6E00',
    },
    text: {
      100: '#E0E0E2',
      200: '#727282',
      300: '#353540',
    },
    background: '#F7F7F7',
  },
})
