import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import en from './languages/en.json'

export const supportedLanguages = ['en']
const resources = {
  en: { translation: en },
}

export const init = () => {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources,
      supportedLngs: supportedLanguages,
      fallbackLng: supportedLanguages[0],
      returnNull: true,
      returnEmptyString: false,
      keySeparator: false,
      detection: {
        lookupLocalStorage: 'lng',
        order: ['localStorage', 'navigator'],
        caches: ['localStorage'],
        excludeCacheFor: ['cimode'],
      },
      react: {
        useSuspense: true,
      },
      debug: true,
    })
}

export default i18n
