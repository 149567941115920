export default {
  home: '/',
  login: '/login',
  resetPassword: '/reset-password',
  clients: '/clients',
  reports: '/reports',
  dashboard: '/dashboard',
  help: '/help',
  changePassword: '/change-password/:token',
  createPassword: '/create-password/:token',
}
