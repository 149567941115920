import { onError } from '@apollo/client/link/error'
import { ApolloClient, createHttpLink, ApolloLink, InMemoryCache, fromPromise, split } from '@apollo/client'

import path from 'app/navigation'
import { UPDATE_TOKENS } from 'app/graphql/mutations'
import { LOGOUT } from 'app/graphql/mutations'
import {
  devSFDIGraphqlUrl,
  devFABGraphqlUrl,
  prodSFDIGraphqlUrl,
  prodFABGraphqlUrl,
  isProduction,
} from 'app/helpers/config'
import history from 'app/history'

const refreshAuthToken = () =>
  apolloClient.mutate({
    mutation: UPDATE_TOKENS,
  })
const logout = () =>
  apolloClient.mutate({
    mutation: LOGOUT,
  })

const SFDILink = createHttpLink({
  uri: isProduction ? prodSFDIGraphqlUrl : devSFDIGraphqlUrl,
  credentials: 'include',
})

const FABLink = createHttpLink({
  uri: isProduction ? prodFABGraphqlUrl : devFABGraphqlUrl,
  credentials: 'include',
})

const httpLink = split(operation => operation.getContext().clientName === 'FAB', FABLink, SFDILink)

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    // User access token has expired
    if (graphQLErrors[0].extensions.code === 'UNAUTHORISED') {
      // Refresh token through async request
      return fromPromise(
        refreshAuthToken()
          .then()
          .catch(async () => {
            return await logout()
              .then(() => {
                history.push(path.login)
              })
              .then(() => {
                apolloClient.resetStore()
              })
          }),
      ).flatMap(() => forward(operation))
    }

    return graphQLErrors.forEach(({ message, locations, path: errorPath }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${errorPath}`),
    )
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([errorLink, httpLink]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
  },
})

export default apolloClient
