import React, { FunctionComponent, PropsWithChildren } from 'react'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { ChakraProvider } from '@chakra-ui/react'

import apolloClient from 'app/apollo/apolloClient'
import history from 'app/history'
import { theme } from 'app/styles'
import { init as initTranslations } from 'app/translations'

const AppProviders: FunctionComponent<PropsWithChildren> = ({ children }) => {
  initTranslations()

  return (
    <HistoryRouter history={history}>
      <ApolloProvider client={apolloClient}>
        <ChakraProvider theme={theme}>{children}</ChakraProvider>
      </ApolloProvider>
    </HistoryRouter>
  )
}

export { AppProviders }
