import { Flex, Spinner } from '@chakra-ui/react'
import React, { FunctionComponent } from 'react'

interface Props {
  overlay?: boolean
}

const SpinnerComponent: FunctionComponent<Props> = ({ overlay = false }) => (
  <Flex alignItems="center" justifyContent="center" height={overlay ? '100vh' : '100%'} flex={1} width="full">
    <Spinner size="lg" />
  </Flex>
)

export default SpinnerComponent
