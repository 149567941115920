import React, { FunctionComponent, Suspense, lazy } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import path from 'app/navigation'
import { Spinner } from 'app/components/spinner'

const PrivateLayout = lazy(() => import('app/layout/private-layout'))
const PublicLayout = lazy(() => import('app/layout/public-layout'))
const LoginPage = lazy(() => import(/* webpackChunkName: "login"*/ 'app/pages/login-page'))
const ResetPasswordPage = lazy(() => import(/* webpackChunkName: "reset-password"*/ 'app/pages/reset-password-page'))
const ChangePasswordPage = React.lazy(
  () => import(/* webpackChunkName: "change-password-page"*/ 'app/pages/change-password-page'),
)
const CreatePasswordPage = React.lazy(
  () => import(/* webpackChunkName: "create-password-page"*/ 'app/pages/create-password-page'),
)
const DashboardPage = lazy(() => import(/* webpackChunkName: "dashboard"*/ 'app/pages/dashboard-page'))
const ReportsPage = lazy(() => import(/* webpackChunkName: "dashboard"*/ 'app/pages/reports-page'))
const ClientsPage = lazy(() => import(/* webpackChunkName: "dashboard"*/ 'app/pages/clients-page'))
const HelpPage = lazy(() => import(/* webpackChunkName: "dashboard"*/ 'app/pages/help-page'))

const App: FunctionComponent = () => (
  <Routes>
    <Route element={<PublicLayout />}>
      <Route
        path={path.login}
        element={
          <Suspense fallback={<Spinner />}>
            <LoginPage />
          </Suspense>
        }
      />
      <Route
        path={path.resetPassword}
        element={
          <Suspense fallback={<Spinner />}>
            <ResetPasswordPage />
          </Suspense>
        }
      />
      <Route
        path={path.changePassword}
        element={
          <Suspense fallback={<Spinner />}>
            <ChangePasswordPage />
          </Suspense>
        }
      />
      <Route
        path={path.createPassword}
        element={
          <Suspense fallback={<Spinner />}>
            <CreatePasswordPage />
          </Suspense>
        }
      />
    </Route>
    <Route element={<PrivateLayout />}>
      <Route
        path={path.dashboard}
        element={
          <Suspense fallback={<Spinner />}>
            <DashboardPage />
          </Suspense>
        }
      />
      <Route
        path={path.reports}
        element={
          <Suspense fallback={<Spinner />}>
            <ReportsPage />
          </Suspense>
        }
      />
      <Route
        path={path.clients}
        element={
          <Suspense fallback={<Spinner />}>
            <ClientsPage />
          </Suspense>
        }
      />
      <Route
        path={path.help}
        element={
          <Suspense fallback={<Spinner />}>
            <HelpPage />
          </Suspense>
        }
      />
    </Route>
    <Route path="/*" element={<Navigate replace to={path.dashboard} />} />
  </Routes>
)

export default App
