import React from 'react'
import { createRoot } from 'react-dom/client'

import { AppProviders } from 'app/app-providers'
import App from 'app/app'

const rootNode = document.getElementById('root')

if (rootNode) {
  createRoot(rootNode).render(
    <AppProviders>
      <App />
    </AppProviders>,
  )
}
